export const CliApi = {
  name: 'CLI & API',
  href: '/docs/cli-api/',
  key: 'cliapi',
  useInPath: '/docs/file-system-api',
  posts: [
    {
      name: 'Vercel CLI',
      href: '/docs/cli/',
      description: 'Manage your Projects from the command line',
      isProduct: true,
      posts: [
        {
          name: 'Deploying from CLI',
          href: '/docs/cli/deploying-from-cli',
        },
        {
          name: 'Project Linking',
          href: '/docs/cli/project-linking',
        },
        {
          name: 'Global Options',
          href: '/docs/cli/global-options',
        },
        // commands
        {
          name: 'alias',
          href: '/docs/cli/alias',
          isCode: true,
        },
        {
          name: 'bisect',
          href: '/docs/cli/bisect',
          isCode: true,
        },
        {
          name: 'build',
          href: '/docs/cli/build',
          isCode: true,
        },
        {
          name: 'certs',
          href: '/docs/cli/certs',
          isCode: true,
        },
        {
          name: 'deploy',
          href: '/docs/cli/deploy',
          isCode: true,
        },
        {
          name: 'dev',
          href: '/docs/cli/dev',
          isCode: true,
        },
        {
          name: 'dns',
          href: '/docs/cli/dns',
          isCode: true,
        },
        {
          name: 'domains',
          href: '/docs/cli/domains',
          isCode: true,
        },
        {
          name: 'env',
          href: '/docs/cli/env',
          isCode: true,
        },
        {
          name: 'git',
          href: '/docs/cli/git',
          isCode: true,
        },
        {
          name: 'help',
          href: '/docs/cli/help',
          isCode: true,
        },
        {
          name: 'init',
          href: '/docs/cli/init',
          isCode: true,
        },
        {
          name: 'inspect',
          href: '/docs/cli/inspect',
          isCode: true,
        },
        {
          name: 'install',
          href: '/docs/cli/install',
          isCode: true,
        },
        {
          name: 'link',
          href: '/docs/cli/link',
          isCode: true,
        },
        {
          name: 'list',
          href: '/docs/cli/list',
          isCode: true,
        },
        {
          name: 'login',
          href: '/docs/cli/login',
          isCode: true,
        },
        {
          name: 'logout',
          href: '/docs/cli/logout',
          isCode: true,
        },
        {
          name: 'logs',
          href: '/docs/cli/logs',
          isCode: true,
        },
        {
          name: 'project',
          href: '/docs/cli/project',
          isCode: true,
        },
        {
          name: 'promote',
          href: '/docs/cli/promote',
          isCode: true,
        },
        {
          name: 'pull',
          href: '/docs/cli/pull',
          isCode: true,
        },
        {
          name: 'redeploy',
          href: '/docs/cli/redeploy',
          isCode: true,
        },
        {
          name: 'remove',
          href: '/docs/cli/remove',
          isCode: true,
        },
        {
          name: 'rollback',
          href: '/docs/cli/rollback',
          isCode: true,
        },
        {
          name: 'secrets',
          href: '/docs/cli/secrets',
          isCode: true,
        },
        {
          name: 'switch',
          href: '/docs/cli/switch',
          isCode: true,
        },
        {
          name: 'teams',
          href: '/docs/cli/teams',
          isCode: true,
        },
        {
          name: 'whoami',
          href: '/docs/cli/whoami',
          isCode: true,
        },
      ],
    },
    {
      name: 'Vercel REST API',
      href: '/docs/rest-api/',
      description: 'Use HTTP requests to interact with your account',
      isProduct: true,
      posts: [
        {
          name: 'Endpoints',
          href: '/docs/rest-api/endpoints',
          isDynamic: true,
        },
        {
          name: 'Errors',
          href: '/docs/rest-api/errors',
        },
        {
          name: 'Interfaces',
          href: '/docs/rest-api/interfaces',
        },
        {
          name: 'Building Integrations',
          href: '/docs/rest-api/vercel-api-integrations',
        },
      ],
    },
    {
      name: 'Build Output API',
      href: '/docs/build-output-api/v3',
      description: 'File-system-based specification of a Deployment',
      isProduct: true,
      posts: [
        {
          name: 'Configuration',
          href: '/docs/build-output-api/v3/configuration',
          singleFile: true,
        },
        {
          name: 'Vercel Primitives',
          href: '/docs/build-output-api/v3/primitives',
          singleFile: true,
        },
        {
          name: 'Features',
          href: '/docs/build-output-api/v3/features',
          singleFile: true,
        },
      ],
    },
  ],
};
